body{
  font-size: 17px;
}

p{
  max-width: 720px;
  line-height:25px;
}
ul{
  margin-top: 10px;
  li{
    line-height: 25px;
    margin-bottom: 5px;
  }

  &.with-icons{
    list-style: none;
    padding: 0;
    margin: 0;
    li a, li{
      display: flex;
      align-items: center;
      svg{
        margin-right: 10px;
      }
    }
  }
}

h1, h3{
  margin-top:50px;
}

h1{
  @media(max-width: $mobile){
    font-size: 28px;
  }
}
.contact-form .material-tf{
  label, input{
    font-size: 16px;
    width: 300px;
  }
  display: block;
  width: 300px;
}

button a, button a:hover{
  color: #fff;
  text-decoration: none;
}

a:hover{
  cursor: pointer;
}

h4{
  margin-top: 25px;
}
