body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

.header {
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  height: 125px;
  z-index: 100; }
  @media (min-width: 768px) {
    .header {
      position: inherit; } }
  .header .top {
    background-color: #2688ba;
    min-height: 40px;
    text-align: right; }
    .header .top ul {
      display: inline-block;
      list-style: none;
      margin: 10px 0; }
      .header .top ul li {
        display: inline;
        margin-right: 15px; }
        .header .top ul li:last-child {
          margin-right: 0; }
        .header .top ul li a {
          display: inline-flex;
          align-items: center;
          color: #fff; }
          .header .top ul li a svg {
            width: 20px;
            height: 20px;
            margin-right: 10px; }
  .header .bottom {
    background-color: #EC8F1B;
    height: 25px; }
  .header .logo-container {
    position: relative;
    height: 25px; }
    .header .logo-container .logo {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      position: absolute;
      bottom: 5px;
      left: 0;
      font-weight: bold;
      color: white; }
      .header .logo-container .logo:hover {
        text-decoration: none; }
      .header .logo-container .logo span {
        display: block;
        font-size: 18px;
        font-style: italic; }
        .header .logo-container .logo span:last-child {
          text-transform: uppercase;
          font-size: 36px;
          line-height: 26px; }

.header-filler {
  height: 120px; }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

.navigation {
  min-height: 45px;
  text-align: left;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.12);
  background: #fff; }
  @media (min-width: 768px) {
    .navigation {
      text-align: right; } }
  .navigation .menu-button {
    color: #2688ba;
    padding: 10px 0;
    cursor: pointer;
    display: inline-block; }
    .navigation .menu-button svg {
      width: 30px;
      height: 30px;
      stroke-width: 3px; }
    @media (min-width: 768px) {
      .navigation .menu-button {
        display: none; } }
  .navigation .menu {
    width: 300px;
    z-index: -1;
    height: 100vh;
    background: white;
    left: 0;
    transition: left 0.3s linear;
    list-style: none;
    margin: 10px 0;
    position: absolute;
    top: 0;
    padding: 10px;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.12); }
    .navigation .menu.open {
      left: 0px; }
    .navigation .menu.closed {
      left: -100%; }
    .navigation .menu li > ul {
      position: absolute;
      display: none;
      width: 250px;
      text-align: left;
      background: #FFF;
      left: 0;
      top: 40px;
      padding: 10px 0;
      z-index: 2;
      box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.12); }
      .navigation .menu li > ul:before {
        content: " ";
        position: absolute;
        width: 100%;
        height: 10px;
        background: #fff;
        top: -10px; }
      .navigation .menu li > ul li {
        padding: 0 10px;
        font-size: 14px;
        display: block; }
        .navigation .menu li > ul li a {
          display: flex;
          padding: 5px 0; }
          .navigation .menu li > ul li a span {
            flex: 1 1; }
      @media (max-width: 768px) {
        .navigation .menu li > ul {
          display: block;
          position: inherit;
          width: 100%;
          margin: 0;
          padding: 0;
          top: 0;
          box-shadow: none;
          margin: 10px 0; }
          .navigation .menu li > ul li:first-child {
            margin-top: 0; } }
    .navigation .menu li {
      position: relative;
      margin: 5px 0;
      padding: 0 10px;
      color: #2688ba;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer; }
      .navigation .menu li:first-child {
        margin-top: 110px; }
      .navigation .menu li:last-child {
        margin-right: 0; }
      .navigation .menu li:hover, .navigation .menu li a:hover {
        color: #EC8F1B;
        text-decoration: none; }
        .navigation .menu li:hover ul, .navigation .menu li a:hover ul {
          display: block; }
    @media (min-width: 768px) {
      .navigation .menu {
        position: inherit;
        padding: 0;
        display: block;
        width: auto;
        height: auto;
        box-shadow: none;
        margin: 0; }
        .navigation .menu li {
          display: inline-block;
          padding: 10px; }
          .navigation .menu li:first-child {
            margin-top: 0; } }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

.footer {
  margin-top: 50px; }
  .footer .container {
    padding-left: 5px;
    padding-right: 5px; }
  @media (min-width: 768px) {
    .footer {
      margin-top: 75px; } }
  .footer h3 {
    color: #2688ba; }
  .footer .top {
    border-top: solid #2688ba 5px;
    padding: 20px 0 30px;
    background: #171717;
    color: #fff; }
    .footer .top .footer-container {
      display: flex; }
      .footer .top .footer-container .contact-info {
        flex: 1 1; }
        .footer .top .footer-container .contact-info h3 {
          margin-top: 20px; }
      .footer .top .footer-container .bovag-logo {
        margin-top: 20px; }
  .footer .bottom {
    padding: 30px 0;
    background: #2688ba;
    color: #fff; }
    .footer .bottom a {
      color: #fff; }
  .footer ul {
    margin-top: 20px;
    list-style: none;
    padding: 0; }
    .footer ul li {
      line-height: 20px; }
      .footer ul li a {
        color: #fff;
        display: inline-flex;
        align-items: center;
        color: #fff; }
        .footer ul li a svg {
          width: 20px;
          height: 20px;
          margin-right: 10px; }
      .footer ul li.tel {
        margin-top: 20px; }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

.about-bovag.static {
  text-align: right;
  padding-right: 100px;
  padding-top: 50px; }
  .about-bovag.static .description {
    position: relative;
    right: inherit;
    top: inherit;
    width: auto; }
    @media (max-width: 768px) {
      .about-bovag.static .description:after, .about-bovag.static .description:before {
        left: 100%;
        top: 35px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; } }
  @media (max-width: 768px) and (min-width: 768px) {
    .about-bovag.static .description:after, .about-bovag.static .description:before {
      top: 35px; } }
    @media (max-width: 768px) {
      .about-bovag.static .description:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: #fff;
        border-width: 15px;
        margin-top: -15px; }
      .about-bovag.static .description:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: #171717;
        border-width: 18px;
        margin-top: -18px; } }
  .about-bovag.static .bovag-logo {
    display: inline-block;
    position: absolute;
    right: 0;
    cursor: default; }
    .about-bovag.static .bovag-logo img {
      cursor: default; }

.about-bovag {
  position: relative; }
  .about-bovag .description {
    top: 20px;
    right: calc(100% + 25px);
    background: #fff;
    color: #171717;
    display: block;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.12);
    padding: 20px;
    text-align: left;
    border: solid 2px #171717;
    border-radius: 10px;
    position: absolute;
    width: 350px; }
    .about-bovag .description ul {
      list-style: disc outside none;
      list-style: initial;
      padding-left: 40px; }
    .about-bovag .description:after, .about-bovag .description:before {
      left: 100%;
      top: 35px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
      @media (min-width: 768px) {
        .about-bovag .description:after, .about-bovag .description:before {
          top: 35px; } }
    .about-bovag .description:after {
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #fff;
      border-width: 15px;
      margin-top: -15px; }
    .about-bovag .description:before {
      border-color: rgba(194, 225, 245, 0);
      border-left-color: #171717;
      border-width: 18px;
      margin-top: -18px; }
    @media (max-width: 768px) {
      .about-bovag .description {
        right: 0;
        bottom: calc(100% + 10px);
        top: auto;
        left: auto;
        width: 300px; }
        .about-bovag .description:after, .about-bovag .description:before {
          top: calc(100% - 15px);
          left: calc(100% - 50px); }
        .about-bovag .description:after {
          border-color: rgba(136, 183, 213, 0);
          border-top-color: #fff;
          border-width: 15px;
          margin-top: 15px; }
        .about-bovag .description:before {
          border-color: rgba(194, 225, 245, 0);
          border-top-color: #171717;
          border-width: 15px;
          margin-top: 17px; } }

.bovag-logo img {
  width: 60px;
  height: 90px;
  cursor: pointer; }

.bovag-logo.black-border img {
  width: 70px;
  height: 100px;
  border: solid #000 5px; }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

.heading {
  background-image: url(/static/media/heading-img.ec00d68f.jpg);
  min-height: 350px;
  background-position: 50% calc(50% - 50px);
  background-size: cover; }

.home .heading-image {
  border-bottom: solid #2688ba 10px; }
  @media (max-width: 768px) {
    .home .heading-image {
      background-image: url(/static/media/heading-img.ec00d68f.jpg);
      min-height: 250px;
      background-position: calc(50% + 120px) 50%;
      background-size: cover; } }
  .home .heading-image .bovag-logo {
    position: absolute;
    right: 5px;
    top: 125px; }
  @media (min-width: 768px) {
    .home .heading-image .bovag-logo {
      display: none; }
    .home .heading-image img {
      width: 100%; } }

.home .over-toine {
  margin-top: 75px;
  margin-bottom: 75px; }
  @media (max-width: 768px) {
    .home .over-toine {
      margin-top: 40px; } }
  .home .over-toine .author {
    display: block;
    margin-top: 20px; }
  .home .over-toine .image-container {
    text-align: center; }
  .home .over-toine img {
    width: 100%;
    max-width: 300px;
    padding: 0;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 768px) {
      .home .over-toine img {
        width: auto;
        max-width: 100%;
        padding: 0 50px 0 0;
        margin-bottom: 0; } }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

.price-table {
  width: 100%;
  margin: 20px 0 40px;
  border-top: solid 4px #EC8F1B;
  border-bottom: solid 4px #EC8F1B; }
  @media (min-width: 768px) {
    .price-table.middle {
      width: 40%; } }
  .price-table th, .price-table td {
    height: 40px; }
  .price-table thead {
    background-color: #2688ba;
    color: #FFF; }
  .price-table tr th:first-child {
    padding-left: 10px; }
  .price-table.wide th, .price-table.wide td {
    height: 50px;
    line-height: 20px; }

.duration {
  text-decoration: underline;
  font-size: 20px; }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

.itheorie {
  margin-top: 50px; }
  @media (min-width: 768px) {
    .itheorie {
      position: relative; }
      .itheorie a {
        right: 0;
        position: absolute; } }

.content ul {
  max-width: 720px; }

ol.nested > li {
  margin-top: 30px; }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

body {
  font-size: 17px; }

p {
  max-width: 720px;
  line-height: 25px; }

ul {
  margin-top: 10px; }
  ul li {
    line-height: 25px;
    margin-bottom: 5px; }
  ul.with-icons {
    list-style: none;
    padding: 0;
    margin: 0; }
    ul.with-icons li a, ul.with-icons li {
      display: flex;
      align-items: center; }
      ul.with-icons li a svg, ul.with-icons li svg {
        margin-right: 10px; }

h1, h3 {
  margin-top: 50px; }

@media (max-width: 768px) {
  h1 {
    font-size: 28px; } }

.contact-form .material-tf {
  display: block;
  width: 300px; }
  .contact-form .material-tf label, .contact-form .material-tf input {
    font-size: 16px;
    width: 300px; }

button a, button a:hover {
  color: #fff;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

h4 {
  margin-top: 25px; }

.App > .container {
  padding-left: 5px;
  padding-right: 5px; }

.faq .section {
  margin-top: 40px;
  border-bottom: solid 2px #EC8F1B; }

