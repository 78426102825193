@import 'shared/shared.scss';

.heading{
  background-image: url("../assets/img/heading-img.jpg");
  min-height: 350px;
  background-position: 50% calc(50% - 50px);
  background-size: cover;
}

.home{
  .heading-image{
    border-bottom: solid $primary 10px;
    @media(max-width: $mobile){
      background-image: url("../assets/img/heading-img.jpg");
      min-height: 250px;
      background-position: calc(50% + 120px) 50%;
      background-size: cover;
    }

    .bovag-logo{
      position: absolute;
      right:5px;
      top:125px;
    }

    @media(min-width: $mobile){
      .bovag-logo{
        display: none;
      }
      img{
        width:100%;
      }
    }
  }


  .over-toine{
    margin-top:75px;
    margin-bottom: 75px;
    @media(max-width: $mobile){
      margin-top:40px;
    }
    
    .author{
      display: block;
      margin-top:20px;
    }
    .image-container{
      text-align: center;
    }
    img{
      width: 100%;
      max-width: 300px;
      padding:0;
      margin-bottom: 25px;
      margin-left: auto;
      margin-right: auto;
      @media(min-width: $mobile){
        width: auto;
        max-width: 100%;
        padding: 0 50px 0 0;
        margin-bottom: 0;
      }
    }
  }
}
