@import 'shared/shared.scss';

.itheorie{
  margin-top: 50px;
  @media(min-width: $mobile){
    position: relative;
    a{
      right:0;
      position: absolute;
    }
  }
}

.content{
  ul{
    max-width: 720px;
  }
}

ol.nested > li{
  margin-top: 30px;
}
