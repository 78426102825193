@import 'shared/shared.scss';

.navigation{
  min-height: 45px;
  text-align: left;
  @media(min-width: $mobile){
    text-align: right;
  }
  box-shadow: $box-shadow;
  background: #fff;

  .menu-button{
    color:$primary;
    padding:10px 0;
    cursor: pointer;
    svg{
      width:30px;
      height:30px;
      stroke-width:3px;
    }
    display: inline-block;
    @media(min-width: $mobile){
      display: none;
    }
  }

  .menu{
    &.open{
      left:0px;
    }

    &.closed{
      left:-100%;
    }

    li > ul{
      position: absolute;
      display: none;
      width:250px;
      text-align: left;
      background: #FFF;
      left:0;
      top:40px;
      padding:10px 0;
      z-index: 2;
      box-shadow: $box-shadow;
      &:before{
        content: " ";
        position: absolute;
        width: 100%;
        height:10px;
        background: #fff;
        top:-10px;
      }


      li{
        padding:0 10px;
        a{
          display: flex;
          padding:5px 0;
          span{
            flex:1;
          }
        }
        font-size: 14px;
        display: block;
      }

      @media(max-width: $mobile){
        display: block;
        position: inherit;
        width: 100%;
        margin:0;
        padding:0;
        top:0;
        box-shadow: none;
        li:first-child{
          margin-top: 0;
        }
        margin: 10px 0;
      }
    }

    width:300px;

    z-index: -1;
    height: 100vh;
    background: white;
    left: 0;
    transition: left 0.3s linear;
    list-style: none;
    //display: inline-block;
    margin:10px 0;
    position: absolute;
    top:0;
    padding:10px;
    box-shadow: $box-shadow;

    li{
      position: relative;
      &:first-child{
        margin-top:110px;
      }
      //display: inline-flex;
      margin:5px 0;
      padding:0 10px;
      &:last-child{
        margin-right: 0;
      }

      color: $primary;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      &:hover, a:hover{
        color: $secondary;
        text-decoration: none;
        ul{
          display: block;
        }
      }
    }
    //desk
    @media(min-width: $mobile){
      position: inherit;
      padding:0;
      display: block;
      width: auto;
      height: auto;
      box-shadow: none;
      margin:0;

      li{
        display: inline-block;
        padding:10px;
        &:first-child{
          margin-top:0;
        }
      }
    }
  }
}
