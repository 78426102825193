@import 'shared/shared.scss';

.about-bovag.static{
  .description{
    position: relative;
    right: inherit;
    top: inherit;
    width: auto;
    @media(max-width: $mobile){
      &:after, &:before {
        left: 100%;
        top: 35px;
        @media(min-width: $mobile){
          top: 35px;
        }
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: #fff;
        border-width: 15px;
        margin-top: -15px;
      }
      &:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: $dark;
        border-width: 18px;
        margin-top: -18px;
      }
    }
  }
  .bovag-logo{
    display: inline-block;
    position: absolute;
    right: 0;
    cursor: default;
    img{
      cursor: default;
    }
  }

  text-align: right;
  padding-right: 100px;
  padding-top: 50px;
}
.about-bovag{
  position: relative;
  .description{
    top:20px;
    right:calc(100% + 25px);
    background: #fff;;
    color: $dark;
    display: block;
    box-shadow: $box-shadow;
    padding: 20px;
    text-align: left;
    border: solid 2px $dark;
    border-radius: 10px;
    position: absolute;
    width:350px;

    ul{
      list-style: initial;
      padding-left: 40px;
    }

    &:after, &:before {
      left: 100%;
      top: 35px;
      @media(min-width: $mobile){
        top: 35px;
      }
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #fff;
      border-width: 15px;
      margin-top: -15px;
    }
    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-left-color: $dark;
      border-width: 18px;
      margin-top: -18px;
    }

    @media(max-width: $mobile){
      right:0;
      bottom:calc(100% + 10px);
      top:auto;
      left:auto;
      width:300px;

      &:after, &:before {
        top: calc(100% - 15px);
        left: calc(100% - 50px);
      }

      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-top-color: #fff;
        border-width: 15px;
        margin-top: 15px;
      }
      &:before {
        border-color: rgba(194, 225, 245, 0);
        border-top-color: $dark;
        border-width: 15px;
        margin-top: 17px;
      }
    }
  }
}
.bovag-logo{
  img{
    width: 60px;
    height: 90px;
    cursor: pointer;
  }

  &.black-border img{
    width:70px;
    height:100px;
    border: solid #000 5px;
  }
}
