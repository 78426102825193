@import 'shared/shared.scss';

.header{
  color: #fff;
  position: fixed;
  width: 100%;
  top:0;
  height:125px;
  z-index: 100;

  @media(min-width: $mobile){
    position: inherit;
  }

  .top{
    background-color: $primary;
    min-height: 40px;
    text-align: right;
    ul{
      display: inline-block;
      list-style: none;
      margin:10px 0;
      li{
        display: inline;
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
        a{
          display: inline-flex;
          align-items: center;
          color: #fff;
          svg{
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .bottom{
    background-color: $secondary;
    height: 25px;
  }

  .logo-container{
    position: relative;
    height: 25px;
    .logo{
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      position: absolute;
      bottom: 5px;
      left:0;
      font-weight: bold;
      color: white;
      &:hover{
        text-decoration: none;
      }
      span{
        display: block;
        font-size: 18px;
        font-style: italic;
        &:last-child{
          text-transform: uppercase;
          font-size: 36px;
          line-height: 26px;
        }
      }
    }
  }
}

.header-filler{
  height: 120px;
}
