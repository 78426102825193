@import 'shared/shared.scss';

.price-table{
  width: 100%;
  &.middle{
    @media(min-width: $mobile){
      width:40%;
    }
  }
  margin:20px 0 40px;
  th,td{
    height: 40px
  }
  thead{
    background-color: $primary;
    color: #FFF;
  }
  tr th:first-child{
    padding-left: 10px;
  }
  border-top: solid 4px $secondary;
  border-bottom: solid 4px $secondary;

  &.wide{
    th,td{
      height: 50px;
      line-height: 20px
    }
  }
}

.duration{
  text-decoration: underline;
  font-size: 20px;
}
