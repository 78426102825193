@import 'shared/shared.scss';

.footer{
  .container{
    padding-left: 5px;
    padding-right: 5px;
  }

  margin-top: 50px;
  @media(min-width: $mobile){
    margin-top: 75px;
  }
  h3{
    color: $primary;
  }
  .top{
    border-top: solid $primary 5px;
    padding:20px 0 30px;
    background: $dark;
    color: #fff;

    .footer-container{
      display: flex;
      .contact-info{
        h3{
          margin-top: 20px;
        }
        flex: 1;
      }
      .bovag-logo{
        margin-top: 20px;
      }
    }
  }
  .bottom{
    padding:30px 0;
    background: $primary;
    color: #fff;
    a{
      color: #fff;
    }
  }
  ul{
    margin-top:20px;
    list-style: none;
    padding: 0;
    li{
      line-height: 20px;

      a{
        color: #fff;
        display: inline-flex;
        align-items: center;
        color: #fff;
        svg{
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      &.tel{
        margin-top: 20px;
      }
    }
  }
}
